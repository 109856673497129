import { Skeleton } from "@mui/material";
import { useAuth } from "../../hooks/useAuth";
import useTranslation from "../../hooks/useTranslation";
import { AvailableLanguages } from "../../types/locale";
import { BENCHMARKING_SCORE } from "../../types/workplaceCulture";
import getColorByScore from "../../utils/graphs";
import { NoDataState } from "../NoDataState/NoDataState";
import { Typography } from "../ui/Typography/Typography";
import "./Benchmarking.scss";

interface BenchmarkingProps {
  inclusionScore?: number;
  scoreError?: boolean;
  scoreLoading?: boolean;
}

export const Benchmarking = ({
  inclusionScore,
  scoreError,
  scoreLoading = true,
}: BenchmarkingProps) => {
  const { t, i18n } = useTranslation("dashboardPage");
  const { authInfo } = useAuth();

  const handleBenchmarkingScore = (score: number) => {
    if (i18n.language === AvailableLanguages.ICELANDIC) {
      const icelandicScore = score.toString().replace(".", ",");
      return icelandicScore;
    } else {
      return score;
    }
  };

  const renderScoreBubble = (color: string, score?: number) => (
    <div className="Benchmarking__scoreBubble" style={{ background: color }}>
      <div>
        {score && (
          <Typography
            desktop="display2"
            className="Benchmarking__scoreBubble__score"
            color={score >= 2 && score < 3 ? "primary" : "light"}
          >
            {handleBenchmarkingScore(score)}
          </Typography>
        )}
      </div>
    </div>
  );

  return (
    <div className="Benchmarking">
      <div className="Benchmarking__scoreSection">
        <Typography desktop="h2" className="Benchmarking__title">
          {t("benchmarking.scoreTitle", {
            companyName: authInfo?.user?.getCompanyName(),
          })}
        </Typography>
        {inclusionScore &&
          renderScoreBubble(getColorByScore(inclusionScore), inclusionScore)}
        {scoreLoading && <Skeleton height={50} width={"100%"} />}
        {scoreError && (
          <NoDataState info={t("benchmarking.scoreError")} light />
        )}
        {!inclusionScore && !scoreError && !scoreLoading && (
          <Typography
            tagVariant="p"
            desktop="body2"
            className="NoDataState__text__info"
            color={"secondary"}
          >
            {t("benchmarking.noInclusionScore")}
          </Typography>
        )}
      </div>
      <div className="Benchmarking__scoreSection">
        <Typography desktop="h2" className="Benchmarking__title">
          {t("benchmarking.indexTitle")}
        </Typography>
        {renderScoreBubble(
          getColorByScore(BENCHMARKING_SCORE),
          BENCHMARKING_SCORE
        )}
      </div>
    </div>
  );
};
